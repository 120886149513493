<template>
    <SearchModal heading="List School or Division Staff" :loading="$store.getters.loading">

        <form slot="form" class="form-row" @submit.prevent="requestStaff()">
            <v-container>
                <v-row align="center">
                    <v-col cols="9"  md="8">
                        <v-select
                            :items="$store.getters.schools"
                            item-text="Unit Name"
                            item-value="Unit Code"
                            label="Select School/Division"
                            v-model="selectedSchool"
                            ></v-select>
                    </v-col>

                    <v-col cols="3" md="4">
                        <v-btn color="#903"
                            type="submit"
                            @click="requestStaff()"
                            width="100%">
                            <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            
        </form>
    </SearchModal>
</template>

<script>
	import SearchModal from '../components/layouts/SearchModal';

    import {getSchools, getSchoolStaff} from '../lib/api';
    import {getPrivateSchoolStaff} from '../lib/private-api';
    import {debugMsg} from '../lib/helpers';
	export default {
		name: "schools-page",
		components: {
			SearchModal,
		},

		data: () => ({
			selectedSchool: "",
		}),

		methods: {
			requestStaff: function() {
                this.$store.commit('setLoading', true);

                if (this.$store.getters.isLoggedIn) {
                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateSchoolStaff(accessToken, this.selectedSchool)
                                .then((res) => {
                                    this.successSearch(res);
                                })
                                .catch((err) => {
                                    this.errorSearch(err);
                                })
                                },
                        (error) => {
                            this.$store.commit("errorMsg", `There was an error retrieving results`);
                        });
                } else {
				    getSchoolStaff(this.selectedSchool)
					.then((res) => {
                        this.successSearch(res);
                    })
                    .catch((err) => {
                        this.errorSearch(err);
                    })
                }
			},

            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);

                // Check if API returned any results
                if (res.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);
                    this.$router.push({path: `/schools/${this.selectedSchool}/staff`})
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                // display error message
                debugMsg("Error requesting staff: ", err)
                this.$store.commit("errorMsg", `There was an API error: ${err.msg}`);

                this.$store.commit('setLoading', false);
                this.errorLogin(err);
            },


			requestSchools: function() {
                this.$store.commit('setLoading', true);

				getSchools()
					.then((res) => {
                        this.$store.commit('setLoading', false);

                        // Check if API returned any results
                        if (res.data.length == 0) {
                            this.$store.commit("errorMsg", "No results were found");
                        } else {
                            
                            // store result in vuex
                            this.$store.commit("updateSchools", res);
                            this.selectedSchool = this.$store.getters.schools[0]['Unit Code']
                        }
                    })
                    .catch((err) => {
                        // display error message
                        debugMsg("Error requesting schools: ", err);
                        this.$store.commit("errorMsg", "There was an error");

                        this.$store.commit('setLoading', false);
                    })
			}
		},


		created() {
			this.requestSchools();
		}
	}
</script>